import store from '@/store';
import { IRouterGuard } from '@/interfaces/interfaces';

export const authGuard = (params: IRouterGuard) => {
  const {from, to, next, nextGuard} = params;

  if (store.getters.isAuthenticated) {
    return nextGuard!({from, to, next});
  }

  return store.dispatch('authenticate')
    .then(() => {
      if (to.meta.auth && !store.getters.isAuthenticated) {
        store.commit('SET_REDIRECT', to.fullPath);
        return next({name: 'signin'});
      }
      return nextGuard!({from, to, next});
    });
};
